<template>
  <z-section-divider
    id="personal-data"
    :title="$t('guest.personal_data')"
    start-opened
  >
    <template #content>
      <div class="personal-data-section">
        <div class="d-flex align-end">
          <div
            style="width: 25%"
            @click="billed && showWarningEditIsNotPossible()"
          >
            <z-input
              type="text"
              data-cy="name"
              :disabled="disabled || billed || !canEditMainGuestName"
              :label="$t('guest.name')"
              :error-messages="getErrorMessages(v$.name)"
              :value="guest.name"
              @input="$emit('change', { name: $event })"
            />
          </div>

          <div
            style="width: 25%"
            class="mx-3"
            @click="billed && showWarningEditIsNotPossible()"
          >
            <z-input
              type="text"
              data-cy="last-name"
              :disabled="disabled || billed || !canEditMainGuestName"
              :label="$t('guest.last_name')"
              :error-messages="getErrorMessages(v$.lastName)"
              :value="guest.lastName"
              @input="$emit('change', { lastName: $event })"
            />
          </div>

          <div class="birthdate-container" style="width: 50%">
            <p style="font-size: 13px" class="body-text mb-2">
              {{ $t("guest.birthdate") }}
            </p>
            <div class="d-flex">
              <z-input
                hide-details
                type="text"
                mask="##"
                data-cy="birthdate-day"
                :value="guest.birthdateDay"
                :disabled="disabled"
                :label="$t('guest.day')"
                :error-messages="getErrorMessages(v$.birthdate)"
                @input="$emit('change', { birthdateDay: $event })"
                @blur="$emit('blur')"
              />
              <z-select
                class="mx-3"
                hide-details
                style="width: 100%"
                data-cy="birthdate-month"
                :value="guest.birthdateMonth"
                :disabled="disabled"
                :label="$t('guest.month')"
                :items="months"
                :error-messages="getErrorMessages(v$.birthdate)"
                @change="$emit('change', { birthdateMonth: $event })"
              />
              <z-input
                hide-details
                type="text"
                mask="####"
                data-cy="birthdate-year"
                :disabled="disabled"
                :value="guest.birthdateYear"
                :label="$t('guest.year')"
                :error-messages="getErrorMessages(v$.birthdate)"
                @input="$emit('change', { birthdateYear: $event })"
                @blur="$emit('change:watch-age')"
              />
            </div>
            <div class="error--container">
              <p class="error--text">
                {{
                  getErrorMessages(v$.birthdate).length > 0
                    ? getErrorMessages(v$.birthdate)[0]
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="social-name-container">
          <z-checkbox
            class="mt-1"
            data-cy="inform-social-name"
            :disabled="disabled"
            :label="$t('guest.inform_social_name')"
            :value="guest.informSocialName"
            @change="$emit('change', { informSocialName: $event })"
          />

          <z-input
            v-if="guest.informSocialName"
            class="grid-span-2"
            data-cy="social-name"
            :disabled="disabled"
            :label="$t('guest.social_name')"
            :value="guest.socialName"
            :error-messages="getErrorMessages(v$.socialName)"
            @change="$emit('change', { socialName: $event })"
          />
        </div>

        <div class="extra-info-container">
          <z-select
            v-if="!config.personalData?.gender?.default"
            data-cy="gender"
            :disabled="disabled"
            :autocomplete="Math.random()"
            :label="$t('guest.gender')"
            :items="genders"
            :value="guest.gender"
            :error-messages="getErrorMessages(v$.gender)"
            @change="$emit('change', { gender: $event })"
          />

          <z-autocomplete
            v-if="!config.personalData?.residenceCountry?.default"
            :disabled="disabled"
            :items="countries"
            item-value="code"
            :item-text="currentLangItemText"
            data-cy="residency-country"
            :autocomplete="Math.random()"
            :label="$t('guest.residency_country')"
            :error-messages="getErrorMessages(v$.countryId)"
            :value="guest.countryId"
            @change="$emit('change', { countryId: $event })"
          />
          <z-autocomplete
            v-if="!config.personalData?.nationalityCountry?.default"
            :disabled="disabled"
            :items="countries"
            item-value="code"
            :item-text="currentLangItemText"
            :autocomplete="Math.random()"
            data-cy="nationality"
            :label="$t('guest.nationality')"
            :error-messages="getErrorMessages(v$.nationalityId)"
            :value="guest.nationalityId"
            @change="$emit('change', { nationalityId: $event })"
          />
          <z-combobox
            v-if="!config.personalData?.job?.default"
            data-cy="profession"
            :label="
              guest.isChild
                ? $t('guest.profession_optional')
                : $t('guest.profession')
            "
            :disabled="disabled"
            :value="guest.jobName"
            :items="jobs"
            :item-text="currentLangItemText"
            item-value="code"
            :error-messages="getErrorMessages(v$.jobName)"
            @change="onChangeJob"
          >
          </z-combobox>
        </div>
      </div>
    </template>
  </z-section-divider>
</template>

<script>
import { computed } from "vue"
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import i18n from "@/i18n"
import { genders, months } from "@/utils/consts"

export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    jobs: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
    getErrorMessages: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    billed: {
      type: Boolean,
      default: false,
    },
    canEditMainGuestName: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, { emit }) {
    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    const { sendNotification } = useNamespacedActions("notifications", [
      "sendNotification",
    ])

    const onChangeJob = (value) => {
      const jobText =
        currentLang.value === "en" ? "name" : `name_${currentLang.value}`
      if (typeof value === "string") {
        emit("change", {
          jobName: value,
          jobId: null,
        })
      } else if (typeof value === "object") {
        emit("change", {
          jobName: value?.[jobText] ?? "",
          jobId: value?.code ?? "",
        })
      }
    }

    const showWarningEditIsNotPossible = () => {
      sendNotification({
        message: i18n.tc("alerts.reservation_billed_warn"),
        duration: 4000,
        color: "var(--yellow-600)",
      })
    }

    const currentLangItemText = computed(() => {
      return currentLang.value === "en" ? "name" : `name_${currentLang.value}`
    })

    return {
      genders,
      months,
      onChangeJob,
      showWarningEditIsNotPossible,
      currentLangItemText,
    }
  },
}
</script>

<style scoped>
.error--text {
  font-size: 0.75rem;
  padding: 0 12px;
}

.error--container {
  width: 100%;
  height: 26px !important;
}

.personal-data-section {
  display: flex;
  flex-direction: column;
}

.birthdate-container {
  display: grid;
}

.extra-info-container {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}
</style>
