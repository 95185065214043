import find from "lodash.find"
import some from "lodash.some"
import { computed, watch } from "vue"
import {
  useNamespacedGetters,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

/**
 * Composable responsável por gerenciar alterações do documento
 * adicional
 *
 * @private - Evitar utilizar fora do contexto useDocuments
 */
export function useAdditionalDocument() {
  const { guest } = useNamespacedState("guest", ["guest"])
  const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
  const {
    defaultAdditionalTypes,
    foreignAdditionalTypes,
    minorsAdditionalTypes,
  } = useNamespacedGetters("parameters", [
    "defaultAdditionalTypes",
    "foreignAdditionalTypes",
    "minorsAdditionalTypes",
  ])

  /**
   * Tipos de documentos adicionais
   */
  const additionalDocumentTypes = computed(() => {
    const isBrazilian = guest.value.nationalityId === "BR"
    const isChild = !!guest.value.isChild

    if (isBrazilian) {
      return isChild
        ? minorsAdditionalTypes.value
        : defaultAdditionalTypes.value
    }

    return foreignAdditionalTypes.value
  })

  /**
   * Pega a máscara do documento adicional selecionado
   */
  const additionalDocumentMask = computed(() => {
    const currentType = find(additionalDocumentTypes.value, {
      value: guest.value.additionalDocument.documentTypeId,
    })
    return currentType ? currentType.mask : ""
  })

  /**
   *
   */
  watch(additionalDocumentTypes, (value) => {
    const existsType = some(value, {
      value: guest.value.additionalDocument.documentTypeId,
    })

    if (!existsType && value?.length > 0) {
      changeGuest({
        "additionalDocument.documentTypeId": value[0].value,
        "additionalDocument.documentNumber": "",
      })
    }
  })

  return {
    additionalDocumentMask,
    additionalDocumentTypes,
  }
}
