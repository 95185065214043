<template>
  <z-section-divider :title="$t('guest.contact')" start-opened>
    <template #content>
      <div class="contacts-section">
        <z-input
          type="text"
          placeholder="your.email@mail.com"
          data-cy="email"
          :disabled="disabled"
          :label="
            guest.isChild ? $t('guest.email_optional') : $t('guest.email')
          "
          :value="guest.email"
          :error-messages="getErrorMessages(v$.email)"
          @input="
            $emit('change', {
              email: $event,
            })
          "
        />
        <z-phone-input
          v-if="!config.contact?.mobileNumber?.default"
          is-mobile
          data-cy="mobile-phone"
          :disabled="disabled"
          :label="
            guest.isChild
              ? $t('guest.mobile_phone_optional')
              : $t('guest.mobile_phone')
          "
          :value="guest.mobilePhoneNumber"
          :countries="countries"
          :current-country="guest.mobilePhoneCountryCode"
          :error-messages="getErrorMessages(v$.mobilePhoneNumber)"
          @change:country="$emit('change', { mobilePhoneCountryCode: $event })"
          @input="$emit('change', { mobilePhoneNumber: $event })"
        />
        <z-phone-input
          v-if="!config.contact?.phoneNumber?.default"
          data-cy="phone"
          :disabled="disabled"
          :label="$t('guest.phone')"
          :value="guest.phoneNumber"
          :countries="countries"
          :current-country="guest.phoneCountryCode"
          :error-messages="getErrorMessages(v$.phoneNumber)"
          @change:country="$emit('change', { phoneCountryCode: $event })"
          @input="$emit('change', { phoneNumber: $event })"
        />
        <z-checkbox
          class="grid-span-3"
          color="primary"
          hide-details
          :disabled="disabled || !isValidMobilePhone()(guest.mobilePhoneNumber)"
          :label="$t('guest.wpp_optin')"
          :value="guest.optins[0].isChecked"
          @change="$emit('change', { 'optins[0].isChecked': $event })"
        />
      </div>
    </template>
  </z-section-divider>
</template>

<script>
import countries from "@zoox-ui/data/countries.json"

import { isValidMobilePhone } from "@/validators"

export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    getErrorMessages: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { countries, isValidMobilePhone }
  },
}
</script>

<style lang="scss" scoped>
.contacts-section {
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(3, 1fr);
}
</style>
