import find from "lodash.find"
import some from "lodash.some"
import { computed, watch } from "vue"
import {
  useNamespacedGetters,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

/**
 * Composable responsável por gerenciar alterações do documento
 * principal
 *
 * @private - Evitar utilizar fora do contexto useDocuments
 */
export function useMainDocument() {
  const { guest } = useNamespacedState("guest", ["guest"])
  const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
  const { defaultDocumentTypes, foreignDocumentTypes, minorsDocumentTypes } =
    useNamespacedGetters("parameters", [
      "defaultDocumentTypes",
      "foreignDocumentTypes",
      "minorsDocumentTypes",
    ])

  /**
   * Tipos de documentos
   */
  const documentTypes = computed(() => {
    const isBrazilian = guest.value.nationalityId === "BR"
    const isChild = !!guest.value.isChild

    if (isBrazilian) {
      return isChild ? minorsDocumentTypes.value : defaultDocumentTypes.value
    }

    return foreignDocumentTypes.value
  })

  /**
   * Pega a máscara do documento selecionado
   */
  const documentMask = computed(() => {
    const currentType = find(documentTypes.value, {
      value: guest.value.documents.documentTypeId,
    })
    return currentType ? currentType.mask : ""
  })

  /**
   *
   */
  watch(documentTypes, (value) => {
    const existsType = some(value, {
      value: guest.value.documents.documentTypeId,
    })

    if (!existsType && value?.length > 0) {
      changeGuest({
        "documents.documentTypeId": value[0].value,
        "documents.documentNumber": "",
      })
    }
  })

  return {
    documentTypes,
    documentMask,
  }
}
