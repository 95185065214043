import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"

import { useAdditionalDocument } from "./_useAdditionalDocument"
import { useMainDocument } from "./_useMainDocument"

/**
 * Composable criado para tratar dos documentos com base
 * nas configurações do guest atual
 */
export function useDocuments() {
  const { isAdditionalDocument } = useNamespacedState("guest", [
    "isAdditionalDocument",
  ])
  const { changeGuest, setAdditionalDocument } = useNamespacedMutations(
    "guest",
    ["setAdditionalDocument", "changeGuest"]
  )
  const { saveFrontDocumentsImage } = useStoreAction(
    "guest",
    "saveFrontDocumentsImage"
  )
  const { saveBackDocumentsImage } = useStoreAction(
    "guest",
    "saveBackDocumentsImage"
  )

  const { documentMask, documentTypes } = useMainDocument()
  const { additionalDocumentMask, additionalDocumentTypes } =
    useAdditionalDocument()

  /**
   * Apaga a imagem frontal do documento
   */
  const eraseFrontDocumentsImage = () => {
    if (isAdditionalDocument.value) {
      changeGuest({
        "additionalDocument.frontImageMemory": "",
        "additionalDocument.front": "",
        "additionalDocument.frontImage": "",
      })
    } else {
      changeGuest({
        "documents.frontImageMemory": "",
        "documents.front": "",
        "documents.frontImage": "",
      })
    }
  }

  /**
   * Apaga a imagem trazeira do documento
   */
  const eraseBackDocumentsImage = () => {
    if (isAdditionalDocument.value) {
      changeGuest({
        "additionalDocument.backImageMemory": "",
        "additionalDocument.back": "",
        "additionalDocument.backImage": "",
      })
    } else {
      changeGuest({
        "documents.backImageMemory": "",
        "documents.back": "",
        "documents.backImage": "",
      })
    }
  }

  const eraseFrontAndBack = () => {
    eraseBackDocumentsImage()
    eraseFrontDocumentsImage()
  }

  return {
    documentMask,
    documentTypes,
    additionalDocumentMask,
    additionalDocumentTypes,

    // Store actions
    setAdditionalDocument,
    saveBackDocumentsImage,
    saveFrontDocumentsImage,

    eraseFrontAndBack,
    eraseBackDocumentsImage,
    eraseFrontDocumentsImage,
  }
}
