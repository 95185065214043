import { computed } from "vue"

import i18n from "@/i18n"

export const months = computed(() => [
  {
    text: i18n.tc("options.january"),
    value: "01",
  },
  {
    text: i18n.tc("options.february"),
    value: "02",
  },
  {
    text: i18n.tc("options.march"),
    value: "03",
  },
  {
    text: i18n.tc("options.april"),
    value: "04",
  },
  {
    text: i18n.tc("options.may"),
    value: "05",
  },
  {
    text: i18n.tc("options.june"),
    value: "06",
  },
  {
    text: i18n.tc("options.july"),
    value: "07",
  },
  {
    text: i18n.tc("options.august"),
    value: "08",
  },
  {
    text: i18n.tc("options.september"),
    value: "09",
  },
  {
    text: i18n.tc("options.october"),
    value: "10",
  },
  {
    text: i18n.tc("options.november"),
    value: "11",
  },
  {
    text: i18n.tc("options.december"),
    value: "12",
  },
])

export const genders = computed(() => [
  { text: i18n.tc("options.masculine"), value: "M" },
  { text: i18n.tc("options.feminine"), value: "F" },
  { text: i18n.tc("options.others"), value: "O" },
  { text: i18n.tc("options.prefer_not_inform"), value: "N" },
])
